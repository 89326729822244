import Http from "..";
import { CurrencyModel } from "../../models/currency";
import ResponseModel from "../../models/response.model";

export namespace CurrencyModule {
  export const list = () =>
    Http.get<ResponseModel<CurrencyModel[]>>(`v1/currency`);

  export const get = (currency_id: number) =>
    Http.get<ResponseModel<any>>(`v1/currency/${currency_id}`);

  export const add = (title: string, icon: File, slug: string) => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("icon", icon);
    formData.append("slug", slug);
    return Http.post<ResponseModel<any>>(`v1/currency/add`, formData);
  };

  export const update = (
    currency_id: number,
    title: string,
    icon: File,
    slug: string
  ) => {
    const formData = new FormData();
    formData.append("currency_id", currency_id + "");
    formData.append("title", title);
    formData.append("icon", icon);
    formData.append("slug", slug);
    return Http.post<ResponseModel<any>>(`v1/currency/update`, formData);
  };
}
