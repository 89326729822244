import axios from 'axios';
import { BASEURL } from './url.helper';

export const ACCESSTOKEN = 'access_token';

const Http = axios.create({
  baseURL: BASEURL,
  timeout: 600000,
});
const UNAUTHORIZED = 401;

Http.interceptors.request.use(async (request) => {
  const accessToken = localStorage.getItem(ACCESSTOKEN);
  if (request.headers) request.headers.Authorization = `Bearer ${accessToken}`;

  return request;
});
Http.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      localStorage.clear();
      document.location.href = '/';
    }

    return Promise.reject(error);
  }
);

export default Http;
