import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { SuportModule } from '../../apis/modules/suport';
import { DtailsTicketsModel } from '../../models/details-tickets';

interface ticketDetails {
  ticketDetails?: DtailsTicketsModel;
  loading: boolean;
  loadingClose: boolean;
  loadingAdd: boolean;
  loadingDelete: boolean;
}

const initialState: ticketDetails = {
  loading: false,
  loadingClose: false,
  ticketDetails: {},
  loadingAdd: false,
  loadingDelete: false,
};
const ticketDetails = createSlice({
  name: 'ticketDetails',
  initialState,
  reducers: {
    deleteMasageState(state, action: PayloadAction<string>) {
      if (state.ticketDetails) {
        const filter = state.ticketDetails.messages?.filter(
          (item) => item.id !== action.payload
        );

        state.ticketDetails.messages = [...(filter ?? [])];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ticketDetailsState.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ticketDetailsState.fulfilled, (state, action) => {
      state.ticketDetails = action.payload.data;
      state.loading = false;
    });
    builder.addCase(ticketDetailsState.rejected, (state) => {
      state.loading = false;
    });
    // ==========================================================
    builder.addCase(addReply.pending, (state) => {
      state.loadingAdd = true;
    });
    builder.addCase(addReply.fulfilled, (state, action) => {
      state.ticketDetails = action.payload.data;
      state.loadingAdd = false;
    });
    builder.addCase(addReply.rejected, (state) => {
      state.loadingAdd = false;
    });
    // ==========================================================

    builder.addCase(ticketClose.pending, (state) => {
      state.loadingClose = true;
    });
    builder.addCase(ticketClose.fulfilled, (state, action) => {
      state.ticketDetails = action.payload.data;
      state.loadingClose = false;
    });
    builder.addCase(ticketClose.rejected, (state) => {
      state.loadingClose = false;
    });
    // ==========================================================
    builder.addCase(ticketOpen.pending, (state) => {
      state.loadingClose = true;
    });
    builder.addCase(ticketOpen.fulfilled, (state, action) => {
      state.ticketDetails = action.payload.data;
      state.loadingClose = false;
    });
    builder.addCase(ticketOpen.rejected, (state) => {
      state.loadingClose = false;
    });
    // ===========================================================
    builder.addCase(deleteMasage.pending, (state) => {
      state.loadingDelete = true;
    });
    builder.addCase(deleteMasage.fulfilled, (state, action) => {
      // state.ticketDetails = action.payload.data;
      state.loadingDelete = false;
    });
    builder.addCase(deleteMasage.rejected, (state) => {
      state.loadingDelete = false;
    });
  },
});
export const ticketDetailsList = (state: RootState) => state.ticketDetails;
export default ticketDetails.reducer;
export const { deleteMasageState } = ticketDetails.actions;
export const ticketDetailsState = createAsyncThunk(
  'ticket/detail',
  async (id: number, thunkAPI) => {
    try {
      const { data } = await SuportModule.detail(id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const ticketClose = createAsyncThunk(
  'ticket/close',
  async (id: number, thunkAPI) => {
    try {
      const { data } = await SuportModule.close(id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const ticketOpen = createAsyncThunk(
  'ticket/open',
  async (id: number, thunkAPI) => {
    try {
      const { data } = await SuportModule.open(id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const addReply = createAsyncThunk(
  'ticket/replay',
  async (
    param: {
      image: File[];
      id: number;
      voices: File[];
      text: string;
      vedeo: File[];
    },
    thunkAPI
  ) => {
    try {
      const { data } = await SuportModule.replay(
        param.image,
        param.id,
        param.voices,
        param.text,
        param.vedeo
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteMasage = createAsyncThunk(
  'ticket/deleteMasage',
  async (
    param: {
      id: number;
      message_id: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await SuportModule.deleteMasage(
        param.id,
        param.message_id
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
