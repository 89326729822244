import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { TurnsModule } from '../../apis/modules/turn';
import { TurnItemModel, TurnModel } from '../../models/turn';
import { RequestsModel } from '../../models/turn/requests';

export interface TurnState {
  turns?: TurnModel[];
  requests?: RequestsModel[];
  request?: RequestsModel;
  turn?: TurnModel;
  loading: boolean;
}

const initialState: TurnState = {
  loading: false,
};

export const turnSlice = createSlice({
  name: 'turn',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTurnList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTurnList.fulfilled, (state, action) => {
      state.turns = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getTurnList.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTurn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTurn.fulfilled, (state, action) => {
      state.turn = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getTurn.rejected, (state) => {
      state.loading = false;
    });
    // ============================================================
    builder.addCase(updateTurn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTurn.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateTurn.rejected, (state) => {
      state.loading = false;
    });
    // ============================================================
    builder.addCase(verifyTurns.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyTurns.fulfilled, (state, action) => {
      state.turn = action.payload.data;
      state.loading = false;
    });
    builder.addCase(verifyTurns.rejected, (state) => {
      state.loading = false;
    });

    // ============================================================
    builder.addCase(updateVisibleTurns.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateVisibleTurns.fulfilled, (state, action) => {
      state.turn = action.payload.data;
      state.loading = false;
    });
    builder.addCase(updateVisibleTurns.rejected, (state) => {
      state.loading = false;
    });
    // ============================================================
    builder.addCase(requestsTurns.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestsTurns.fulfilled, (state, action) => {
      state.requests = action.payload.data;
      state.loading = false;
    });
    builder.addCase(requestsTurns.rejected, (state) => {
      state.loading = false;
    });
    // ============================================================
    builder.addCase(requestsDetailTurns.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestsDetailTurns.fulfilled, (state, action) => {
      state.request = action.payload.data;
      state.loading = false;
    });
    builder.addCase(requestsDetailTurns.rejected, (state) => {
      state.loading = false;
    });
    // ============================================================
    builder.addCase(requestsSearchTurns.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestsSearchTurns.fulfilled, (state, action) => {
      state.requests = action.payload.data;
      state.loading = false;
    });
    builder.addCase(requestsSearchTurns.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const turnState = (state: RootState) => state.turn;

// Action creators are generated for each case reducer function

export default turnSlice.reducer;

// turn list
export const getTurnList = createAsyncThunk(
  'turn/list',
  async (param: { date?: string }, thunkAPI) => {
    try {
      const { data } = await TurnsModule.list(param?.date ?? '');
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// turn add
export const addTurn = createAsyncThunk(
  'turn/add',
  async (
    param: {
      currency_id: number;
      date: string;
      start_time: string;
      end_time: string;
      period: number;
      visible: boolean;
      capacity: number;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await TurnsModule.add(
        param.currency_id,
        param.date,
        param.start_time,
        param.end_time,
        param.period,
        param.visible,
        param.capacity
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTurn = createAsyncThunk(
  'turn/update',
  async (
    param: {
      turn_id: number;
      currency_id: number;
      date: string;
      start_time: string;
      end_time: string;
      period: number;
      visible: boolean;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await TurnsModule.update(
        param.turn_id,
        param.currency_id,
        param.date,
        param.start_time,
        param.end_time,
        param.period,
        param.visible
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTurn = createAsyncThunk(
  'turn/get',
  async (
    param: {
      turn_id: number;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await TurnsModule.get(param.turn_id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const suggestionTurn = createAsyncThunk(
  'turn/suggestion',
  async (
    param: {
      turn_id: number;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await TurnsModule.suggestion(param.turn_id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyTurns = createAsyncThunk(
  'turn/verify',
  async (
    param: {
      turn_id: number;
      turns: TurnItemModel[];
    },
    thunkAPI
  ) => {
    try {
      const { data } = await TurnsModule.verifyTurns(
        param.turn_id,
        param.turns
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateVisibleTurns = createAsyncThunk(
  'turn/update-visible',
  async (
    param: {
      turn_id: number;
      visible: boolean;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await TurnsModule.updateVisibleTurns(
        param.turn_id,
        param.visible
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const requestsTurns = createAsyncThunk(
  'turn/requests',
  async (param: { date?: string }, thunkAPI) => {
    try {
      const { data } = await TurnsModule.requests(param?.date ?? '');
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const requestsSearchTurns = createAsyncThunk(
  'turn/requests/search',
  async (param: { text?: string }, thunkAPI) => {
    try {
      const { data } = await TurnsModule.requestsSearch(param?.text + '');
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const requestsDetailTurns = createAsyncThunk(
  'turn/requests/detail',
  async (param: { request_id?: number }, thunkAPI) => {
    try {
      const { data } = await TurnsModule.requestsDetail(param?.request_id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
