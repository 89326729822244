import { TurnItemModel, TurnModel } from './../../models/turn/index';
import Http from '..';
import ResponseModel from '../../models/response.model';
import { RequestsModel } from '../../models/turn/requests';

export namespace TurnsModule {
  export const list = (date: string) =>
    Http.get<ResponseModel<TurnModel[]>>(`v1/turn/${date}`);

  export const requests = (date: string) =>
    Http.get<ResponseModel<RequestsModel[]>>(`v1/requests/${date}`);

  export const requestsSearch = (text: string) =>
    Http.get<ResponseModel<RequestsModel[]>>(`v1/requests/search/${text}`);

  export const requestsDetail = (request_id?: number) =>
    Http.get<ResponseModel<RequestsModel>>(`v1/requests/detail/${request_id}`);

  export const get = (rate_id: number) =>
    Http.get<ResponseModel<TurnModel>>(`v1/turn/${rate_id}`);

  export const suggestion = (rate_id: number) =>
    Http.get<ResponseModel<TurnItemModel[]>>(`v1/turn/suggestion/${rate_id}`);

  export const add = (
    currency_id: number,
    date: string,
    start_time: string,
    end_time: string,
    period: number,
    visible: boolean,
    capacity: number
  ) => {
    const params = {
      currency_id: currency_id,
      date: date,
      start_time: start_time,
      end_time: end_time,
      period: period,
      visible: visible,
      capacity: capacity,
    };
    return Http.post<ResponseModel<any>>(`v1/turn/add`, params);
  };

  export const verifyTurns = (turn_id: number, turns: TurnItemModel[]) => {
    const params = {
      turn_id: turn_id,
      turns: turns,
    };
    return Http.post<ResponseModel<TurnModel>>(`v1/turn/verify-turns`, params);
  };

  export const update = (
    turn_id: number,
    currency_id: number,
    date: string,
    start_time: string,
    end_time: string,
    period: number,
    visible: boolean
  ) => {
    const params = {
      turn_id: turn_id,
      currency_id: currency_id,
      date: date,
      start_time: start_time,
      end_time: end_time,
      period: period,
      visible: visible,
    };
    return Http.post<ResponseModel<any>>(`v1/turn/update`, params);
  };

  export const updateVisibleTurns = (turn_id: number, visible: boolean) => {
    const params = {
      turn_id: turn_id,
      visible: visible,
    };
    return Http.post<ResponseModel<TurnModel>>(
      `v1/turn/update-visible`,
      params
    );
  };
}
