import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import counterReducer from './modules/counter';
import authReducer from './modules/auth';
import currencyReducer from './modules/currency';
import rateReducer from './modules/rate';
import turnReducer from './modules/turn';
import dashboardReducer from './modules/dashboard';
import globalReducer from './modules/global';
import listReducer from './modules/support';
import listanswersReducer from './modules/predefinedAnswers';
import ticketDetailsListReduser from './modules/ticketDetails';
import productReduser from './modules/product';
import frequentlyAskedQuestionsReduser from './modules/frequently-asked-questions';
import profileUserReduser from './modules/profile';
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    currency: currencyReducer,
    rate: rateReducer,
    turn: turnReducer,
    dashboard: dashboardReducer,
    global: globalReducer,
    list: listReducer,
    listanswers: listanswersReducer,
    ticketDetails: ticketDetailsListReduser,
    product: productReduser,
    frequently_asked_questions: frequentlyAskedQuestionsReduser,
    profileUser: profileUserReduser,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
