import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { getUser } from '../../apis/modules/profile-user';
import { ProfileUserType } from '../../models/profile-user';
import { UserModel } from '../../models/user';
interface CounterState {
  user?: ProfileUserType;
  loading?: boolean;
}
const initialState: CounterState = {
  user: undefined,
  loading: true,
};
const profileUser = createSlice({
  name: 'profileUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfileUserList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProfileUserList.fulfilled, (state, action) => {
      state.user = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getProfileUserList.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const getProfileUser = (state: RootState) => state.profileUser;
export default profileUser.reducer;

export const getProfileUserList = createAsyncThunk(
  'profileUser/getUser',
  async (param: {}, thunkAPI) => {
    try {
      const { data } = await getUser();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
