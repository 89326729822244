import Http from '..';
import { DtailsTicketsModel } from '../../models/details-tickets';
import ResponseModel from '../../models/response.model';
import { TicketModel } from '../../models/ticket';
export namespace SuportModule {
  export const list = (date?: string, product_id?: number) => {
    let url = 'v1/tickets/list';
    if (product_id) {
      url += `/${product_id}`;
    }
    if (date) {
      url += `/${date}`;
    }
    return Http.get<ResponseModel<TicketModel[]>>(url);
  };

  export const search = (text?: string, date?: string, product_id?: number) => {
    let url = 'v1/tickets/search';
    let queryString = [];
    if (text) {
      queryString.push(`text=${text}`);
    }
    if (date) {
      queryString.push(`date=${date}`);
    }
    if (product_id) {
      queryString.push(`product_id=${product_id}`);
    }
    if (queryString.length) {
      url += `?${queryString.join('&')}`;
    }

    return Http.get<ResponseModel<TicketModel[]>>(url);
  };

  export const detail = (id: number) =>
    Http.get<ResponseModel<DtailsTicketsModel>>(`v1/tickets/${id}`);

  export const close = (id: number) =>
    Http.put<ResponseModel<DtailsTicketsModel>>(`v1/tickets/close/${id}`);
  export const open = (id: number) =>
    Http.put<ResponseModel<DtailsTicketsModel>>(`v1/tickets/open/${id}`);
  export const replay = (
    files: File[],
    ticket_id: number,
    voices: File[],
    message: string,
    vedeo: File[]
  ) => {
    var bodyFormData = new FormData();
    bodyFormData.append('message', message);
    bodyFormData.append('ticket_id', ticket_id + '');
    files.forEach((element) => {
      bodyFormData.append('images[]', element);
    });
    // debugger
    voices.forEach((voice) => {
      // const file = new File([voice], "audio.mp3");
      bodyFormData.append('voices[]', voice);
    });
    vedeo.forEach((vedeo) => {
      // const file = new File([voice], "audio.mp3");
      bodyFormData.append('videos[]', vedeo);
    });

    return Http.post<ResponseModel<DtailsTicketsModel>>(
      `v1/tickets/replay`,
      bodyFormData
    );
  };
  export const sendferToTechnicalTeam = (ticket_id: string) => {
    const params = {
      ticket_id: ticket_id,
    };
    return Http.post<ResponseModel<any>>('v1/tickets/transfer', params);
  };
  export const deleteMasage = (ticket_id: number, message_id: string) => {
    let url = `v1/tickets/${ticket_id}/message/${message_id}/delete`;
    return Http.delete<ResponseModel<any[]>>(url);
  };
  export const deleteTickets = (ticket_id: number) => {
    let url = `v1/tickets/delete/${ticket_id}`;
    return Http.delete<ResponseModel<any[]>>(url);
  };
  export const unReadMasage = (ticket_id: number) =>
    Http.put<ResponseModel<DtailsTicketsModel>>(
      `v1/tickets/unRead/${ticket_id}`
    );
}
