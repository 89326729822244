import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { CurrencyModule } from '../../apis/modules/currency';
import { CurrencyModel } from '../../models/currency';

export interface CurrencyState {
  currencies?: CurrencyModel[];
  loading: boolean;
}

const initialState: CurrencyState = {
  currencies: [],
  loading: false,
};

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCurrencyList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCurrencyList.fulfilled, (state, action) => {
      state.currencies = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getCurrencyList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addCurrency.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addCurrency.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const CurrencyState = (state: RootState) => state.currency;

// Action creators are generated for each case reducer function

export default currencySlice.reducer;

// currency list
export const getCurrencyList = createAsyncThunk(
  'currency/list',
  async (param, thunkAPI) => {
    try {
      const { data } = await CurrencyModule.list();
      return data;
    } catch (error) {
      console.log('======= error');
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// currency add
export const addCurrency = createAsyncThunk(
  'currency/add',
  async (param: { title: string; icon: File; slug: string }, thunkAPI) => {
    try {
      const { data } = await CurrencyModule.add(
        param.title,
        param.icon,
        param.slug
      );
      return data;
    } catch (error) {
      console.log('======= error');
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
