import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { AuthModule } from '../../apis/modules/auth';

export interface AuthState {
  access_token: string;
  captcha?: string;
  loading: boolean;
  captchaLoading: boolean;
}

const initialState: AuthState = {
  access_token: '',
  captcha: '',
  loading: false,
  captchaLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{ access_token?: string }>) => {
      state.access_token += action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginWithUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginWithUser.fulfilled, (state, action) => {
      const access_token = action.payload.data?.access_token;
      if (access_token) {
        localStorage.setItem('access_token', access_token);
      }
      state.loading = false;
    });
    builder.addCase(loginWithUser.rejected, (state) => {
      state.loading = false;
    });
    // ============================================================
    builder.addCase(captchaRequest.pending, (state) => {
      state.captchaLoading = true;
    });
    builder.addCase(captchaRequest.fulfilled, (state, action) => {
      state.captchaLoading = false;
      state.captcha = action.payload?.data;
    });
    builder.addCase(captchaRequest.rejected, (state) => {
      state.captchaLoading = false;
    });
  },
});

export const AuthState = (state: RootState) => state.auth;

// Action creators are generated for each case reducer function
export const { login } = authSlice.actions;

export default authSlice.reducer;

// Login
export const loginWithUser = createAsyncThunk(
  'users/loginWithUser',
  async (
    param: { username: string; password: string; captcha: string },
    thunkAPI
  ) => {
    try {
      const { data } = await AuthModule.login(
        param.username,
        param.password,
        param.captcha
      );
      return data;
    } catch (error: any) {
      let { response } = error;
      let message = 'نام کاربری یا رمز عبور اشتباه است';
      if (response && response.data && response.data.message) {
        message = response.data.message;
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const captchaRequest = createAsyncThunk(
  'users/captcha',
  async (param, thunkAPI) => {
    try {
      const { data } = await AuthModule.captcha();
      return data;
    } catch (error: any) {}
  }
);
