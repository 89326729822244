import Http from '..';
import { AuthLoginModel } from '../../models/auth';
import ResponseModel from '../../models/response.model';

export namespace AuthModule {
  export const login = (
    username?: string,
    password?: string,
    captcha?: string
  ) => {
    const params = {
      username: username,
      password: password,
      captcha: captcha,
    };
    return Http.post<ResponseModel<AuthLoginModel>>('v1/auth/login', params);
  };
  export const captcha = () => {
    return Http.get<ResponseModel<string>>('v1/captcha');
  };
}
