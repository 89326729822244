import { product } from './product';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { PredefinedAnswersModule } from '../../apis/modules/predefined-answers';
import { SuportModule } from '../../apis/modules/suport';
import { ExtractError } from '../../utils/error';
import { PredefinedAnswersModel } from '../../models/predefined-answers';
import { FrequentlyAskedQuestionsModule } from '../../apis/modules/frequently-asked-questions';

export interface TurnState {
  frequentlyAskedQuestions?: PredefinedAnswersModel[];
  loading: boolean;
}
const initialState: TurnState = {
  frequentlyAskedQuestions: [],
  loading: false,
};
const frequentlyAskedQuestionsSlice = createSlice({
  name: 'frequentlyAskedQuestions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addFrequentlyAskedQuestions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addFrequentlyAskedQuestions.fulfilled, (state, action) => {
      state.frequentlyAskedQuestions = action.payload.data;
      state.loading = false;
    });
    builder.addCase(addFrequentlyAskedQuestions.rejected, (state) => {
      state.loading = false;
    });
    // ============================================================
    builder.addCase(listFrequentlyAskedQuestions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listFrequentlyAskedQuestions.fulfilled, (state, action) => {
      state.frequentlyAskedQuestions = action.payload.data;
      state.loading = false;
    });
    builder.addCase(listFrequentlyAskedQuestions.rejected, (state) => {
      state.loading = false;
    });
    // ============================================================
    builder.addCase(deleteFrequentlyAskedQuestions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteFrequentlyAskedQuestions.fulfilled, (state, action) => {
      state.frequentlyAskedQuestions = action.payload.data;
      state.loading = false;
    });
    builder.addCase(deleteFrequentlyAskedQuestions.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const frequentlyAskedQuestionsState = (state: RootState) => state.frequently_asked_questions;
export default frequentlyAskedQuestionsSlice.reducer;

export const addFrequentlyAskedQuestions = createAsyncThunk(
  'frequently-asked-questions/add',
  async (param: { title: string, des: string }, thunkAPI) => {
    try {
      const {product} = thunkAPI.getState() as RootState;
      if(!product.product_id) {
        return thunkAPI.rejectWithValue('لطفا محصول مورد نظر را انتخاب کنید.');
      }
      if(!param.title) {
        return thunkAPI.rejectWithValue('لطفا عنوان سوال را وارد کنید.');
      }

      if(!param.des) {
        return thunkAPI.rejectWithValue('لطفا توضیحات سوال را وارد کنید.');
      }

      const { data } = await FrequentlyAskedQuestionsModule.add(product.product_id, param.title, param.des);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(ExtractError(error));
    }
  }
);

export const listFrequentlyAskedQuestions = createAsyncThunk('frequently-asked-questions/list',
  async (param: {}, thunkAPI) => {
    try {
      const { data } = await FrequentlyAskedQuestionsModule.list();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(ExtractError(error));
    }
  }
);

export const deleteFrequentlyAskedQuestions = createAsyncThunk('frequently-asked-questions/delete',
  async (param: {frequently_asked_questions_id: number}, thunkAPI) => {
    try {
      const { data } = await FrequentlyAskedQuestionsModule.remove(param.frequently_asked_questions_id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(ExtractError(error));
    }
  }
);
