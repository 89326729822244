import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '..';
import { SuportModule } from '../../apis/modules/suport';
import { TicketModel } from '../../models/ticket';

export interface TurnState {
  ticketsList?: TicketModel[];
  loading: boolean;
  backData?: {
    date?: string;
    search?: string;
  };
  loadingSerch: boolean;
  transferToTechnicalTeamLoading: boolean;
  deleteTicketLoading: boolean;
  unReadLoading: boolean;
}
const initialState: TurnState = {
  ticketsList: [],
  loading: false,
  backData: {
    date: '',
    search: '',
  },
  loadingSerch: false,
  transferToTechnicalTeamLoading: false,
  deleteTicketLoading: false,
  unReadLoading: false,
};
export const suport = createSlice({
  name: 'suport',
  initialState,
  reducers: {
    addText(state, action: PayloadAction<{ date?: string; search?: string }>) {
      state.backData = action.payload;
    },
    readTicket(state, action: PayloadAction<number>) {
      if (state.ticketsList) {
        const findIndex1 = state.ticketsList.findIndex(
          (item) => item.id === action.payload
        );
        state.ticketsList[findIndex1].is_view = '1';
      }
    },
    unReadTicket(state, action: PayloadAction<number>) {
      if (state.ticketsList) {
        const findIndex1 = state.ticketsList.findIndex(
          (item) => item.id === action.payload
        );
        state.ticketsList[findIndex1].is_view = '0';
      }
    },
    deleteTicketReduser(state, action: PayloadAction<number>) {
      if (state.ticketsList) {
        const filter = state.ticketsList.filter(
          (item) => item.id !== action.payload
        );
        state.ticketsList = filter;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestsList.fulfilled, (state, action) => {
      state.ticketsList = action.payload.data;
      state.loading = false;
    });
    builder.addCase(requestsList.rejected, (state) => {
      state.loading = false;
    });
    // ============================================================
    builder.addCase(ticketSearch.pending, (state) => {
      state.loadingSerch = true;
    });
    builder.addCase(ticketSearch.fulfilled, (state, action) => {
      state.ticketsList = action.payload.data;
      state.loadingSerch = false;
    });
    builder.addCase(ticketSearch.rejected, (state) => {
      state.loadingSerch = false;
    });
    // ==========================================================
    builder.addCase(transferToTechnicalTeam.pending, (state) => {
      state.transferToTechnicalTeamLoading = true;
    });
    builder.addCase(transferToTechnicalTeam.fulfilled, (state, action) => {
      const list = state.ticketsList ?? [];
      const fitler = list.filter((item) => item.id !== action.payload.data?.id);
      state.ticketsList = fitler;
      state.transferToTechnicalTeamLoading = false;
    });
    builder.addCase(transferToTechnicalTeam.rejected, (state) => {
      state.transferToTechnicalTeamLoading = false;
    });
    // ===========================================================
    builder.addCase(deleteTicket.pending, (state) => {
      state.deleteTicketLoading = true;
    });
    builder.addCase(deleteTicket.fulfilled, (state, action) => {
      // const list = state.ticketsList ?? [];
      // const fitler = list.filter((item) => item.id !== action.payload.data?.id);
      // state.ticketsList = fitler;
      state.deleteTicketLoading = false;
    });
    builder.addCase(deleteTicket.rejected, (state) => {
      state.deleteTicketLoading = false;
    });
    // ==========================================================

    builder.addCase(unRead.pending, (state) => {
      state.unReadLoading = true;
    });
    builder.addCase(unRead.fulfilled, (state, action) => {
      state.unReadLoading = false;
    });
    builder.addCase(unRead.rejected, (state) => {
      state.unReadLoading = false;
    });
  },
});
export const { addText, readTicket, unReadTicket, deleteTicketReduser } =
  suport.actions;
export const listState = (state: RootState) => state.list;

export default suport.reducer;

export const requestsList = createAsyncThunk(
  'ticket/list',
  async (param: { date?: string; product_id?: number }, thunkAPI) => {
    try {
      const { data } = await SuportModule.list(param.date, param.product_id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const ticketSearch = createAsyncThunk(
  'ticket/search',
  async (
    param: { text?: string; date?: string; product_id?: number },
    thunkAPI
  ) => {
    try {
      const { data } = await SuportModule.search(
        param?.text,
        param.date,
        param.product_id
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const transferToTechnicalTeam = createAsyncThunk(
  'ticket/transferToTechnicalTeam',
  async (ticket_id: string, thunkAPI) => {
    try {
      const { data } = await SuportModule.sendferToTechnicalTeam(ticket_id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteTicket = createAsyncThunk(
  'ticket/deleteTicket',
  async (ticket_id: number, thunkAPI) => {
    try {
      const { data } = await SuportModule.deleteTickets(ticket_id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const unRead = createAsyncThunk(
  'ticket/unRead',
  async (ticket_id: number, thunkAPI) => {
    try {
      const { data } = await SuportModule.unReadMasage(ticket_id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
