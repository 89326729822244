import Http from '..';
import { RateModel } from '../../models/rate';
import ResponseModel from '../../models/response.model';

export namespace RateModule {
  export const list = (date?: string) =>
    Http.get<ResponseModel<RateModel[]>>(`v1/rate/${date ?? ''}`);

  export const get = (rate_id: number) =>
    Http.get<ResponseModel<RateModel>>(`v1/rate/special/${rate_id}`);

  export const add = (
    currency_id: number,
    price_buy: number,
    price_sell: number,
    visible: boolean,
    date: string,
    time: string
  ) => {
    const params = {
      currency_id: currency_id,
      price_buy: price_buy,
      price_sell: price_sell,
      visible: visible,
      date: date,
      time: time,
    };
    return Http.post<ResponseModel<any>>(`v1/rate/add`, params);
  };

  export const update = (
    rate_id: number,
    currency_id: number,
    price_buy: number,
    price_sell: number,
    visible: boolean,
    date: string,
    time: string
  ) => {
    const params = {
      rate_id: rate_id,
      currency_id: currency_id,
      price_buy: price_buy,
      price_sell: price_sell,
      visible: visible,
      date: date,
      time: time,
    };
    return Http.post<ResponseModel<any>>(`v1/rate/update`, params);
  };

  export const remove = (rate_id: number) =>
    Http.delete<ResponseModel<number>>(`v1/rate/delete/${rate_id}`);
}
