import { lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));

function App() {
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          {/* <Route path="/create-account" component={CreateAccount} /> */}
          {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
          {/* Place new routes over this */}
          <Route path="/app" component={Layout} />
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to="/app" />
        </Switch>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
