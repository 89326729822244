import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ProductModule } from "../../apis/modules/product";
import { ProductModle } from "../../models/product";

export interface TurnState {
  products?: ProductModle[];
  product_id?: number;
  loading: boolean;
}
const initialState: TurnState = {
  products: [],
  loading: false,
}
export const product = createSlice({
  name: "product",
  initialState,
  reducers: {
      setProducts: (state, action: PayloadAction<string | undefined>) => {
        state.product_id = Number(action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(requestsProductList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestsProductList.fulfilled, (state, action) => {
      state.products = action.payload.data;
      state.loading = false;
    });
    builder.addCase(requestsProductList.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const productState = (state: RootState) => state.product;
export const { setProducts } = product.actions;
export default product.reducer;

export const requestsProductList = createAsyncThunk(
  "product/list",
  async (param: {}, thunkAPI) => {
    try {
      const { data } = await ProductModule.productList();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
