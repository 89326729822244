import { PredefinedAnswersModel } from '../../models/predefined-answers/index';
import Http from '..';
import ResponseModel from '../../models/response.model';
export namespace PredefinedAnswersModule {
  
  export const add = (product_id?: number, text?: string) => {
    const params = {
      text: text,
      product_id: product_id,
    };
    return Http.post<ResponseModel<PredefinedAnswersModel[]>>(`v1/predefined-answers/add`, params);
  };

  export const list = (product_id?: number) => {
    let url = "v1/predefined-answers/list";
    if(product_id) {
      url += `/${product_id}`;
    }
    return Http.get<ResponseModel<PredefinedAnswersModel[]>>(url);
  };

  export const remove = (predefinedAnswer_id: number) => {
    let url = `v1/predefined-answers/delete/${predefinedAnswer_id}`;
    return Http.delete<ResponseModel<PredefinedAnswersModel[]>>(url);
  };

  
}
