import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface GlobalState {
  open_menu: boolean;
}

const initialState: GlobalState = {
  open_menu: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    openMenu: (state, action: PayloadAction<boolean | undefined>) => {
      state.open_menu = !state.open_menu;
    },
  },
  extraReducers: (builder) => {},
});

export const globalState = (state: RootState) => state.global;

// Action creators are generated for each case reducer function
export const { openMenu } = globalSlice.actions;

export default globalSlice.reducer;
