import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { RateModule } from '../../apis/modules/rate';
import { RateModel } from '../../models/rate';

export interface RateState {
  rates?: RateModel[];
  rate?: RateModel;
  loading: boolean;
}

const initialState: RateState = {
  rates: [],
  loading: false,
};

export const rateSlice = createSlice({
  name: 'rate',
  initialState,
  reducers: {
    setRateEdit: (state, action: PayloadAction<RateModel>) => {
      state.rate = action.payload;
    },
    clearRateEdit: (state) => {
      state.rate = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRateList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRateList.fulfilled, (state, action) => {
      state.rates = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getRateList.rejected, (state) => {
      state.loading = false;
    });
    // ============================================================
    builder.addCase(removeRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeRate.fulfilled, (state, action) => {
      const rate_id = action.payload.data;
      if (state.rates) {
        const rates = state.rates.filter((rate) => rate.id !== rate_id);
        state.rates = rates;
      }
      state.loading = false;
    });
    builder.addCase(removeRate.rejected, (state) => {
      state.loading = false;
    });
    // ============================================================
    builder.addCase(getRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRate.fulfilled, (state, action) => {
      state.rate = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getRate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRate.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateRate.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const RateState = (state: RootState) => state.rate;

// Action creators are generated for each case reducer function
export const { setRateEdit, clearRateEdit } = rateSlice.actions;

export default rateSlice.reducer;

// rate list
export const getRateList = createAsyncThunk(
  'rate/list',
  async (param: { date?: string }, thunkAPI) => {
    try {
      const { data } = await RateModule.list(param?.date);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// rate add
export const addRate = createAsyncThunk(
  'rate/add',
  async (
    param: {
      currency_id: number;
      price_buy: number;
      price_sell: number;
      visible: boolean;
      date: string;
      time: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await RateModule.add(
        param.currency_id,
        param.price_buy,
        param.price_sell,
        param.visible,
        param.date,
        param.time
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateRate = createAsyncThunk(
  'rate/update',
  async (
    param: {
      rate_id: number;
      currency_id: number;
      price_buy: number;
      price_sell: number;
      visible: boolean;
      date: string;
      time: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await RateModule.update(
        param.rate_id,
        param.currency_id,
        param.price_buy,
        param.price_sell,
        param.visible,
        param.date,
        param.time
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// rate remove
export const removeRate = createAsyncThunk(
  'rate/remove',
  async (
    param: {
      rate_id: number;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await RateModule.remove(param.rate_id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getRate = createAsyncThunk(
  'rate/get',
  async (
    param: {
      rate_id: number;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await RateModule.get(param.rate_id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
