import React from 'react';
import LoadingSvg from './atoms/loading';

type ThemedSuspenseType = {
  size?: 'small' | 'mudim' | 'large';
};
function ThemedSuspense(props: ThemedSuspenseType) {
  let classLoding = '';
  switch (props.size) {
    case 'small':
      classLoding = 'w-4';

      break;
    case 'mudim':
      classLoding = 'w-5';
      break;

    default:
      classLoding = 'w-6';
      break;
  }
  return (
    // <div className="w-full my-2 p-6 border text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900">
    //   در حال بارگذاری ...
    // </div>
    <div className="flex items-center justify-center py-4 w-6">
      <LoadingSvg width={classLoding} height={classLoding} />
    </div>
  );
}

export default ThemedSuspense;
