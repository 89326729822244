import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { DashboardModule } from '../../apis/modules/dashboard';
import { DashboardBoxReport } from '../../models/dashboard';

export interface DashboardState {
  boxes: DashboardBoxReport[];
  loading: boolean;
}

const initialState: DashboardState = {
  boxes: [],
  loading: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(dashboardReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(dashboardReport.fulfilled, (state, action) => {
      state.boxes = action.payload.data ?? [];
      state.loading = false;
    });
    builder.addCase(dashboardReport.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const dashboardState = (state: RootState) => state.dashboard;

// Action creators are generated for each case reducer function

export default dashboardSlice.reducer;

// Login
export const dashboardReport = createAsyncThunk(
  'dashboard/report',
  async (param, thunkAPI) => {
    try {
      const { data } = await DashboardModule.report();
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue('opps something went wrong');
    }
  }
);
